@import '../../components/cards/indicatorPillCard/IndicatorsPillCardVariables.scss';

.indicators-template-page {
    padding: 10px;
    h2 {
        text-align: center;
        padding: 20px 40px 20px 0;
        position: relative;
        .open-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            border: none;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.24);
            background-color: white;
            border-radius: 5px;
            padding: 2px;
            color: #070d29;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                background-color: rgba($color: #f2f2f2, $alpha: .4);
                transform: translateY(-55%);
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.24);
            }
            &:active {
                background-color: rgba($color: #062836, $alpha: .3);
            }
            .icon {
                font-size: 27px;
            }
        }
    }
    p {
        text-align: center;
        padding-bottom: 20px;
        font-size: 13px;
    }
    .cards-section {
        &:not(:first-of-type) {
            margin-top: 60px;
        }
        h3 {
            border-bottom: 1px solid rgba($color: #000000, $alpha: .3);
            margin-bottom: 30px;
            padding-bottom: 5px;
        }
        .cards-container {
            display: grid;
            gap: 15px;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            align-items: center;
            justify-items: center;
        }
    }
    .error-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .error {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            padding: 8px;
            border-radius: 5px;
            background-color: rgba(255, 0, 0, .1);
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            .icon {
                color: red;
                font-size: 23px;
                margin-right: 5px;
            }
            span {
                color: red;
                font-size: 12px;
            }
        }
    }
}

@media only screen and (min-width: 500px) {
    .indicators-template-page {
        padding: 15px;
        h2 {
            padding: 20px 0 10px 0;
        }
        .cards-section{
            .cards-container {
                grid-template-columns: repeat(auto-fit, minmax($pill-width + $pill-label-icon-size, 1fr));
            }
        }
    }
    
}