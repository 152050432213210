.tabs {
    ol {
        display: flex;
        li {
            position: relative;
            list-style: none;
            padding: 5px 10px;
            cursor: pointer;
            &.active-tab {
                background-color: #fff;
                cursor: default;
                border-radius: 5px 5px 0 0;
                &:hover .tab-close-icon {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .tab-close-icon {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
                transition: all .3s;
                color: #070d29;
                font-size: 15px;
                cursor: pointer;
                z-index: 1;
                &:hover {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .tabs-content {
        background-color: #fff;
        border-radius: 0 0 5px 5px;
    }
}