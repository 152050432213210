.login {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-modal {
        // background-color: #070d29;
        // padding: 30px;
        border-radius: 5px;
        box-shadow: -5px 5px 15px 1px rgba($color: #000000, $alpha: .3);
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        // gap: 50px;
        overflow: hidden;
        .square {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 30px;
            .logo-img {
                width: 250px;
                height: auto;
            }
            &:nth-child(1) {
                background-color: #070d29;
                padding: 30px;
            }
            .error {
                color: red;
                font-size: 15px;
                margin-top: 15px;
            }
        }
    }
}