@import '../../components/cards/summaryCard/SummaryCardVariables.scss';

.summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    .title-wrapper {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        h2 {
            margin-bottom: 20px;
        }
        .open-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            border: none;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.24);
            background-color: white;
            border-radius: 5px;
            padding: 2px;
            color: #070d29;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                background-color: rgba($color: #f2f2f2, $alpha: .4);
                transform: translateY(-55%);
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.24);
            }
            &:active {
                background-color: rgba($color: #062836, $alpha: .3);
            }
            .icon {
                font-size: 27px;
            }
        }
    }
    .summary-cards {
        width: 100%;
        display: grid;
        gap: 15px;
        grid-template-columns: repeat(auto-fit, minmax($card-width, 1fr));
        align-items: start;
        justify-items: center;
        margin-top: 20px;
    }
}