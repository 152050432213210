// @mixin menu-mixin($closed) {
    .menu {
        color: white;
        &--show {
            display: block;
        }
        &--hide {
            display: none;
        }
        li a {
            color: white;
            text-decoration: none;
        }
        .menu-item {
            color: white;
            text-decoration: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            position: relative;
            padding: 10px 5px;
            &--active {
                background-color: #222b45;
            }
            &:hover {
                background-color: #222b45;
            }
            &--submenu {
    
            }
            .menu-item-icon {
                margin-right: 10px;
                font-size: 16px;
                &--submenu {
                    font-size: 20px !important;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: transform .3s !important;
                    &--rotate180 {
                        transform: translateY(-50%) rotate(-180deg);
                    }
                }
            }
            .menu-item-text {
                white-space: nowrap;
                opacity: 1;
                visibility: visible;
                transition: all .3s;
                font-size: 10px;
                overflow: hidden;
            }
        }
    }
    @media only screen and (min-width: 992px) {
        .menu {
            &.menu--closed {
                .menu-item {
                    &-text {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
    }
// }

// .menu--closed {
//     @include menu-mixin(true);
// }
// .menu--open {
//     @include menu-mixin(false);
// }