.create-views {
    padding: 15px;
    h2 {
        text-align: center;
        margin-bottom: 20px;
    }
    .form-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 20px;
        .create-views-form {
            background-color: #F6F6F6;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px;
            box-shadow: -5px 5px 6px rgba($color: black, $alpha: .1);
            &.menu {
                width: 50%;
                justify-self: center;
            }
            &.indicators {
                width: 50%;
                justify-self: center;
            }
            .group-form-rows {
                box-shadow: 0px 0px 10px rgba($color: black, $alpha: .15);
                border-radius: 5px;
                padding: 10px;
            }
            .form-row {
                align-self: stretch;
                display: flex;
                gap: 20px;
                &.center {
                    align-items: center;
                    justify-content: center;
                }
                .validate-btn {
                    margin: 20px 0;
                    background-color: #FFD100;
                    border: none;
                    outline: none;
                    color: white;
                    padding: 10px 15px;
                    border-radius: 5px;
                    font-size: 15px;
                    cursor: pointer;
                    box-shadow: -2px 2px 5px rgba($color: #000000, $alpha: .3);
                    transition: all .5s;
                    &:hover {
                        transform: translateY(-2px);
                        box-shadow: -2px 4px 5px rgba($color: #000000, $alpha: .3);
                    }
                    &:active {
                        transform: translateY(0);
                        box-shadow: -2px 2px 5px rgba($color: #000000, $alpha: .3);
                    }
                    &:disabled {
                        box-shadow: none;
                        opacity: .5;
                        transform: none;
                        cursor: default;
                    }
                }
                .validated-btn {
                    background-color: green;
                }
            }
            .card-form-wrapper {
                display: flex;
                flex-direction: column;
                position: relative;
                padding: 35px 15px 15px 15px;
                .add-card-btn {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    border: none;
                    outline: none;
                    border-radius: 50%;
                    background-color: #6BB9E8;
                    font-size: 20px;
                    color: #fff;
                    height: 25px;
                    width: 25px;
                    cursor: pointer;
                    box-shadow: -2px 2px 5px rgba($color: #000000, $alpha: .3);
                    transition: all .5s;
                    &:hover {
                        transform: translateY(-2px);
                        box-shadow: -2px 4px 5px rgba($color: #000000, $alpha: .3);
                    }
                    &:active {
                        transform: translateY(0);
                        box-shadow: -2px 2px 5px rgba($color: #000000, $alpha: .3);
                    }
                }
                .card-form {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    height: 780px;
                    overflow: hidden;
                    transition: height .5s;
                    &.hide {
                        height: 40px;
                    }
                    &.card-form--errors {
                        height: 790px;
                    }
                    & > span {
                        background-color: #115448;
                        color: white;
                        padding: 5px;
                        border-radius: 3px;
                        cursor: pointer;
                        position: relative;
                        pointer-events: auto;
                        &:hover .remove-card-btn {
                            opacity: 1;
                            visibility: visible;
                        }
                        .remove-card-btn {
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            transform: translateY(-50%);
                            opacity: 0;
                            visibility: hidden;
                            border: none;
                            outline: none;
                            border-radius: 50%;
                            background-color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 25px;
                            width: 25px;
                            cursor: pointer;
                            transition: all .5s;
                            .remove-icon {
                                font-size: 20px;
                                color: #115448;
                            }
                        }
                    }
                }
            }
            .wrapper-add-btn {
                flex: 1;
                display: flex;
                align-items: center;
                gap: 10px;
                & > button {
                    border: none;
                    cursor: pointer;
                    outline: none;
                    padding: 10px;
                    box-shadow: -2px 2px 5px rgba($color: #000000, $alpha: .3);
                    background-color: #070d29;
                    color: #fff;
                    font-weight: bold;
                    border-radius: 5px;
                    height: fit-content;
                    transition: all .3s;
                    &:hover {
                        transform: translateY(-2px);
                        box-shadow: -2px 4px 5px rgba($color: #000000, $alpha: .3);
                    }
                    &:active {
                        transform: translateY(0);
                        box-shadow: -2px 2px 5px rgba($color: #000000, $alpha: .3);
                    }
                }
            }
            .input-wrapper {
                position: relative;
                flex: 1;
                padding-top: 13px;
                margin-bottom: 15px;
                &--checkbox {
                    flex: 1;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .error {
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        transform: translateY(100%);
                        color: red;
                        font-size: 13px;
                        transition: all .5s;
                        visibility: hidden;
                        opacity: 0;
                        &.show {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
                &--select {
                    flex: 1;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    em {
                        color: red;
                    }
                    .error {
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        transform: translateY(100%);
                        color: red;
                        font-size: 13px;
                        transition: all .5s;
                        visibility: hidden;
                        opacity: 0;
                        &.show {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
                &--textarea {
                    flex: 1;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 15px;
                    em {
                        color: red;
                    }
                    .error {
                        position: absolute;
                        left: 0;
                        bottom: -2px;
                        transform: translateY(100%);
                        color: red;
                        font-size: 13px;
                        transition: all .5s;
                        visibility: hidden;
                        opacity: 0;
                        &.show {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
                input {
                    position: relative;
                    width: 100%;
                    padding: 15px 10px 10px;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    font-size: 13px;
                    letter-spacing: 1px;
                    z-index: 2;
                    &:valid ~ span,
                    &:focus ~ span {
                        transform: translate(-10px, -29px);
                        font-size: 11px;
                    }
                    &:valid ~ i,
                    &:focus ~ i {
                        height: 39px;
                        border-radius: 5px 5px 0 0;
                    }
                    &:-webkit-autofill {
                        background-color: red;
                    }
                }
                span {
                    position: absolute;
                    left: 0;
                    padding: 15px 10px 10px;
                    font-size: 13px;
                    letter-spacing: 1px;
                    color: black;
                    pointer-events: none;
                    transition: all .5s;
                    em {
                        color: red;
                    }
                }
                i {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 0;
                    background-color: white;
                    border-bottom: 1px solid black;
                    transition: all .5s;
                    pointer-events: none;
                    z-index: 1;
                }
                .error {
                    position: absolute;
                    left: 0;
                    bottom: -2px;
                    transform: translateY(100%);
                    color: red;
                    font-size: 13px;
                    transition: all .5s;
                    visibility: hidden;
                    opacity: 0;
                    &.show {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
        .previous-view {
            padding: 15px;
            &.menu {
                .menu-wrapper {
                    background-color: #070d29;
                    width: 225px;
                }
            }
            h3 {
                border-bottom: 1px solid rgba($color: #000000, $alpha: .3);
                margin-bottom: 30px;
                padding-bottom: 5px;
            }
            .card-wrapper {
                width: 100%;
            }
        }
    }
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color: #000000, $alpha: .3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        .modal-card {
            background-color: #fff;
            box-shadow: -5px 5px 6px rgba($color: black, $alpha: .3);
            border-radius: 10px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: center;
            .btns {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
            }
        }
    }
}