@import './IndicatorsPillCardVariables.scss';

.indicator-pill-card-wrapper {
    width: calc(100% - #{$pill-label-icon-size});
    position: relative;
    .label-icon {
        font-size: $pill-label-icon-size;
        color: #fff;
        cursor: help;
        position: absolute;
        left: 1px;
        top: 1px;
    }
    .label {
        font-size: 12px;
        color: #000;
        border-radius: 3px;
        box-shadow: -1px 1px 5px rgba($color: #000000, $alpha: .3);
        background-color: #fff;
        padding: 5px;
        position: absolute;
        top: -5px;
        left: 0;
        transform: translateY(-100%);
        visibility: hidden;
        opacity: 0;
        transition: all .3s;
        &.active {
            visibility: visible;
            opacity: 1;
        }
    }
    .indicator-pill-card {
        display: flex;
        border-radius: 10px;
        width: 100%;
        min-width: 60%;
        height: $pill-height;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        cursor: pointer;
        overflow: hidden;
        transition: all .3s;
        .header {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: $pill-header--padding-vertical 5px;
            color: white;
            flex: 1.5;
            .title {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                font-size: 9px;
                text-align: center;
                height: $pill-height - ($pill-header--padding-vertical * 2);
                padding-left: $pill-label-icon-size - 5px;
                transition: all .3s;
            }
            .description {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                font-weight: 300;
                text-align: center;
                transition: all .3s;
                visibility: hidden;
                opacity: 0;
            }
            .trend-vs-time {
                display: flex;
                align-items: center;
                font-size: 10px;
                font-weight: 500;
                transition: all .3s;
                visibility: hidden;
                opacity: 0;
                .icon {
                    &--down {
                        transform: rotateX(180deg);
                    }
                }
                .time {
                    text-align: center;
                }
            }
        }
        .body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
            .indicator {
                font-size: 13px;
                font-weight: bold;
                text-align: center;
            }
            .goal, .cumplimiento {
                font-size: 0;
                font-weight: 300;
                transition: all .3s;
                visibility: hidden;
                opacity: 0;
            }
        }
        &.active {
            height: $pill-height--active;
            .header {
                .title {
                    height: auto;
                }
                .description {
                    visibility: visible;
                    opacity: 1;
                }
                .trend-vs-time {
                    visibility: visible;
                    opacity: 1;
                }
            }
            .body {
                gap: 5px;
                .goal, .cumplimiento {
                    visibility: visible;
                    opacity: 1;
                    font-size: 12px;
                }
                .cumplimiento {
                    font-size: 10px;
                    font-style: italic;
                }
            }
        }
    }
}

@media only screen and (min-width: 500px) {
    .indicator-pill-card-wrapper {
        width: fit-content;
        .indicator-pill-card {
            width: $pill-width;
            .header {
                flex: 1.25;
            }
            .body {
                .indicator {
                    font-size: 15px;
                }
            }
        }
    }
}