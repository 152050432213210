$navbar-height: 35px;
$sidebar-width--expanded: 220px;
$sidebar-width--collpased: 90px;

.status-findep {
    height: 100vh;
    width: 100%;
}

@mixin layout($closed) {
    $sidebar-width: $sidebar-width--expanded;

    .navbar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 10px;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        height: $navbar-height;
        background-color: #070d29;
        transition: left .3s;
        z-index: 9;

        .logout-btn {
            position: relative;
            color: #fff;
            font-size: 11px;
            display: flex;
            align-items: center;
            background-color: transparent;
            cursor: pointer;
            padding: 3px;
            border-radius: 3px;

            &:hover {
                background-color: rgba($color: #fff, $alpha: .2);
            }

            img {
                height: auto;
                width: 20px;
                border-radius: 50%;
                margin-right: 5px;
            }

            .logout-modal {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: calc(100% + 3px);
                left: 0;
                width: 100%;
                background-color: white;
                border-radius: 3px;
                box-shadow: -1px 1px 5px rgba($color: #000000, $alpha: .3);
                padding: 10px 0;
                visibility: hidden;
                opacity: 0;
                transition: all .3s;

                button {
                    background-color: #b23b3b;
                    border: none;
                    outline: none;
                    color: white;
                    padding: 5px 7px;
                    border-radius: 5px;
                    font-size: 11px;
                    cursor: pointer;
                    box-shadow: -2px 2px 3px rgba($color: #000000, $alpha: .3);
                    transition: all .3s;

                    &:hover {
                        transform: translateY(-1px);
                        box-shadow: -2px 3px 3px rgba($color: #000000, $alpha: .3);
                    }

                    &:active {
                        transform: translateY(0);
                        box-shadow: -2px 2px 3px rgba($color: #000000, $alpha: .3);
                    }

                    &:disabled {
                        box-shadow: none;
                        opacity: .5;
                        transform: none;
                        cursor: default;
                    }
                }
            }

            .show {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .sidebar {
        $menu-toggle-bg-color: white;
        display: flex;
        flex-direction: column;
        position: fixed;
        background-color: #070d29;
        width: $sidebar-width;
        top: 0;
        bottom: 0;
        left: -$sidebar-width;
        transition: all .3s;
        z-index: 10;

        &.active {
            left: 0;

            .menu-toggle {
                padding-left: 5px;

                &::before {
                    transform: translateY(0) rotate(45deg);
                }

                &::after {
                    transform: translateY(0) rotate(-45deg);
                    box-shadow: 0 0 0 $menu-toggle-bg-color;
                }
            }
        }

        .menu-toggle {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(100%);
            width: $navbar-height;
            height: $navbar-height;
            padding-left: 10px;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            transition: all .3s;

            &::before {
                content: '';
                position: absolute;
                width: 25px;
                height: 2px;
                background: $menu-toggle-bg-color;
                transform: translateY(-8px);
                transition: .3s;
            }

            &::after {
                content: '';
                position: absolute;
                width: 25px;
                height: 2px;
                background: $menu-toggle-bg-color;
                transform: translateY(8px);
                box-shadow: 0 -8px 0 $menu-toggle-bg-color;
                transition: .3s;
            }
        }

        .sidebar-logo {
            min-height: 100px;
            max-height: 100px;
            flex: 1;
            position: relative;

            .logo-img {
                width: 190px;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all .3s;
            }
        }

        .sidebar-menu-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            overflow-x: hidden;

            .sidebar-menu {
                flex: 0;
            }

            .close-btn {
                cursor: pointer;
                color: white;
                flex: 1;
                display: none;
                justify-content: flex-end;
                padding-top: 10px;

                &:hover {
                    background-color: rgba($color: white, $alpha: .02);
                }
            }
        }
    }

    .sidebar-background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        visibility: hidden;
        opacity: 0;
        transition: all .3s;
        z-index: 10;

        &.active {
            visibility: visible;
            opacity: 1;
            background-color: rgba($color: #070d29, $alpha: .4);
            right: 0;
            left: $sidebar-width;
        }
    }

    .content {
        position: fixed;
        left: 0;
        right: 0;
        top: $navbar-height;
        bottom: 0;
        overflow-y: auto;
        transition: left .3s;
        z-index: 8;
    }

    @media only screen and (min-width: 992px) {
        @if $closed {
            $sidebar-width: $sidebar-width--collpased;
        }

        @else {
            $sidebar-width: $sidebar-width--expanded;
        }

        .navbar {
            left: $sidebar-width;
        }

        .sidebar {
            left: 0;
            width: $sidebar-width;

            .menu-toggle {
                display: none;
            }

            .sidebar-menu-container {
                .close-btn {
                    display: flex;
                }
            }

            .sidebar-logo {
                .logo-img {
                    @if $closed {
                        top: 0;
                        left: 0;
                        transform: none;
                        width: auto;
                        height: $navbar-height;
                    }
                }
            }
        }

        .sidebar-background {
            display: none;
        }

        .content {
            left: $sidebar-width;
        }
    }
}

.closed {
    @include layout(true);
}

.open {
    @include layout(false);
}